*::selection {
  background-color: rgba(0, 255, 217, 0.99);
  color: #FFF;
}
*::-moz-selection {
  background-color: rgba(0, 255, 217, 0.99);
  color: #FFF;
}

*::-webkit-scrollbar {
  width: 3px;
}
*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background: var(--text-color);
  border-radius: 50%;
  border: none;
}

html,
body {
  width: 100%;
  height: 100%;
  color: var(--text-color);
  background-color: var(--background-color);
  overflow-x: hidden;
}

