@font-face {
  font-family: "H Nazza";
  src: url("../assets/fonts/Helvetica-Narrow_Regular.eot");
  src: url("../assets/fonts/Helvetica-Narrow_Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/Helvetica-Narrow_Regular.woff") format("woff"),
    url("../assets/fonts/Helvetica-Narrow_Regular.ttf") format("truetype"),
    url("../assets/fonts/Helvetica-Narrow_Regular.svg#Helvetica-Narrow_Regular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: "H Nazza", sans-serif;
  font-size: 30px;
  line-height: 34px;
  color: var(--text-color);
}

a {
  color: inherit;
  @include hover {
    color: inherit;
  }
}

p {
  margin-bottom: 30px;
}

.cards__grid-item {
  font-size: 12px;
  line-height: 14px;
}

@include mobile {
  html,
  body {
    font-size: 20px;
    line-height: 24px;
  }
  p {
    margin-bottom: 20px;
  }
}
