@import '../../styles/breakpoints.scss';

.info{

    &__button{
        margin: 30px;
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
    }

    &__wrapper{
        width: 60%;
        padding: 0 20%;
        height: var(--app-height);
        top: calc(var(--app-height) * 1.1);;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: var(--background-color);
        position: fixed;
        transition: top 0.4s ease-in-out;
        z-index: 9;
        text-align: center;
        overflow-y: auto;
    }

    &__text{
        padding: 0 30px;
        margin: 30px 0;
        overflow-y: auto;

    }
}

@include mobile{

    .info{

        &__button{
            margin: 20px;
        }
    
        &__wrapper{
            width: calc(100% - 40px);
            padding: 0 20px;
        }
    
        &__text{
            padding: 0 30px;
            margin: 90px 0 15vh 0;
            overflow-y: auto;
    
        }
    }

}