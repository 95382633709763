@import '../../styles/breakpoints.scss';

.project{

    &__back{
        position: absolute;
        left: 30px;
    }
    &__title{
        display: block;
        text-align: center;
        position: sticky;
        top: 0;
        padding: 30px 20%;
    }

    &__images-wrapper{
        padding-top: 100px;
        margin: 0 30%;
    }

    &__image{
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }

    &__text-wrapper{
        padding: 100px 0;
        margin: 0 20%;
        p{
            margin-bottom: 30px;
        }
    }

    &__buy-button{
        display: block;
        margin: 100px auto;
        text-align: center;
        
    }
}

@include mobile{

    .project{

        &__back{
            left: 20px;
        }
        &__title{
            padding: 20px 25%;
        }
    
        &__images-wrapper{
            padding-top: 30px;
            margin: 0 30px;
        }
    
        &__image{
            display: block;
            width: 100%;
            height: auto;
            margin-bottom: 30px;
        }
    
        &__text-wrapper{
            padding: 60px 0 20vh 0;
            margin: 0 30px;
            p{
                margin-bottom: 20px;
            }
        }
    }


}