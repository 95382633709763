@import '../../styles/breakpoints.scss';

.logo{

    &__wrapper{
        width: 15vw;
        height: auto;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 20;
        margin: 30px 30px 30px 30px;
        text-align: center;
        font-size: 16px;
        line-height: 18px;
    }
    &__canvas-wrapper{
        width: 100%;
        height: 100%;
        margin-bottom: 10px;
        cursor: grab;
        border-radius: 50%;
        border-top: 1px solid var(--text-color) ;
        border-bottom: 1px solid var(--text-color) ;
        overflow: hidden;
        &:active{
            cursor: grabbing;
        }
    }
}

@include mobile{

    .logo{

        &__wrapper{
            width: calc(100vw - 60px);
            height: 10vh;
            margin: 30px;
            bottom: 30px;
        }
    }

}