@import '../../styles/breakpoints.scss';

.cards{

    &__back{
        position: absolute;
        left: 30px;
    }
    &__title{
        display: block;
        text-align: center;
        position: fixed;
        top: 0;
        padding: 30px 20%;
        width: 60%;
        z-index: 4;
        &.preview{
            position: absolute;
        }
    }

    &__logo-wrapper{
        position: fixed;
        width: 100%;
        height: calc(var(--app-height) * 0.9);
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &__logo{
        display: block;
        width: calc(100% - 40px);
        height: auto;
        margin: 20px;
    }

    &__scene-wrapper{
        width: 100%;
        height: calc(var(--app-height) * 0.9);
        margin-bottom: 30px;
        cursor: grab;
        position: relative;
        &:active{
            cursor: grabbing;
        }
    }

    &__main-caption{
        position: absolute;
        margin: 30px;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    &__info-wrapper{
        margin: 0 20% 30px 20%;
    }

    &__grid-wrapper{
        margin: 0 15px;
        padding: 30px 0 100px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    &__grid-item{
        width: calc(25% - 30px);
        padding: 0 15px 15px 15px;
        cursor: pointer;
        text-decoration: none;
        text-align: left;
        &.placeholder{
            cursor: default;
        }
    }

    &__grid-image{
        display: block;
        width: 100%;
        height: auto;
        border-radius: 10px;
        margin-bottom: 10px;
    }


    &__preview-wrapper{
        width: 100%;
        height: var(--app-height);
        position: fixed;
        top: 0;
        left: 100%;
        z-index: 5;
        background-color: var(--background-color);
        transition: left 0.4s ease-in-out;
        cursor: grab;
        &:active{
            cursor: grabbing;
        }
        &.active{
            left: 0;
        }
    }
}

@include mobile{

    .cards{

        &__back{
            left: 20px;
        }
        &__title{
            padding: 20px 25%;
            width: 50%;
            position: fixed;
            top: 0;
        }
    
        &__logo-wrapper{
            height: calc(var(--app-height) * 0.8);
        }
    
        &__scene-wrapper{
            width: 100%;
            height: calc(var(--app-height) * 0.8);
        }
    
        &__main-caption{
            position: absolute;
            margin: 20px;
        }
    
        &__info-wrapper{
            margin: 0 30px 30px 30px;
        }
    
        &__grid-wrapper{
            margin: 0 10px;
            padding: 30px 0 20vh 0;
        }
    
        &__grid-item{
            width: calc(50% - 30px);
            padding: 0 15px 15px 15px;
        }
    
        &__grid-image{
            border-radius: 5px;
            margin-bottom: 10px;
        }
    }
}